import React from "react"

import { graphql } from "gatsby"
import { useIntl } from "react-intl"

import withTemplate, { TemplateDefaultPropTypes } from "../withTemplate"
import SEO from "~/components/Seo/Seo"
import { Col, Container, Heading, Row } from "~/components/ui"
import VideoPlayer from "~/components/VideoPlayer/VideoPlayer"
import { Video } from "~/models/Video"

import css from "./VideoPage.module.scss"

interface PropTypes extends TemplateDefaultPropTypes {
  data: {
    video: Video
  }
}

function VideoPage(props: PropTypes) {
  const intl = useIntl()
  const video = props.data.video

  return (
    <div className={css.videoPage}>
      <SEO title={video.title} location={props.location} />

      <Container>
        <Row>
          <Col width={[12, 8]}>
            {video && (
              <>
                <Heading variant="extraLarge">{video.title}</Heading>
                <VideoPlayer
                  src={video.hostedVideoUrl}
                  poster={video.videoPreviewThumbnail}
                  autoPlay
                  verticalColor={"original"}
                  format={"other"}
                  ratio={video.height / video.width}
                  videoFit="contain"
                />
              </>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  )
}
export const query = graphql`
  query VideoPagePage($language: String, $videoId: String) {
    video(id: { eq: $videoId }, language: { eq: $language }) {
      ...Video
    }
  }
`

export default withTemplate(VideoPage)
